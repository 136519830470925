@font-face {
    font-family: 'Rocher';
    src: url(https://assets.codepen.io/9632/RocherColorGX.woff2);
  }
  


/* Home.module.css */
.section_wrapper {
    background-image: url(../../assets/background.png);
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 70px;
    background-color: rgba(247, 152, 152, 0.175);
    background-blend-mode: darken;
    
}

.section_wrapper h1{
    font-family: 'Rocher';
    font-size: 40px;
    
}


.contents {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    gap: 60px;
    position: relative;
}

.main_asset {

    width: 300px;
}
/* .rainbow_asset {
    width: 400px;
    height: 400x;
} */
.social {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.logs {
    /* background-color: black; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 220px;
    border-radius: 100px;
    margin: 10px;
    cursor: pointer;
}


.logss {
    /* background-color: black; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    border-radius: 100px;
    margin: 10px;
    cursor: pointer;
}

.logs_drug {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 190px;
    border-radius: 100px;
    margin: 10px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .section_wrapper {
        gap: 50px;
    }

    .main_asset {
        width: 100%;
        width: 100px;
        width: 130px;
        margin: 10px;
    }

    .section_wrapper {
        gap: 30px;
    }

    .logs_drug {
        height: 65px;
    }

    .section_wrapper h1 {
        font-size: 12px;
    }

    .logs {
        height:65px;}

}